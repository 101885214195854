@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: rgb(3 0 20 / 1)
}

.cursive {
  font-family: "Cedarville Cursive", cursive;
}
.Welcome-text {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    linear-gradient(90.01deg, #e59cff 0.01%, #ba9cff 50.01%, #9cb2ff 100%);
  background-blend-mode: normal, screen;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Welcome-box {
  isolation: isolate;
  overflow: hidden;
  align-items: center;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  border-radius: 32px;
  box-shadow: inset 0 -7px 11px #a48fff1f;
  display: flex;

  position: relative;
  width: -moz-max-content;
  width: max-content;
  transition: 0.45s cubic-bezier(0.6, 0.6, 0, 1) box-shadow;
}

.button-primary {
  background: linear-gradient(
      180deg,
      rgba(60, 8, 126, 0) 0%,
      rgba(60, 8, 126, 0.32) 100%
    ),
    rgba(113, 47, 255, 0.12);
  box-shadow: inset 0 0 12px #bf97ff3d;
}
.button-primary:hover {
  background: linear-gradient(
      180deg,
      rgba(60, 8, 126, 0) 0%,
      rgba(60, 8, 126, 0.42) 100%
    ),
    rgba(113, 47, 255, 0.24);
  box-shadow: inset 0 0 12px #bf97ff70;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}



html {
  scroll-behavior: smooth;
}
.mobile{
  display:none!important;
}
@media screen and (max-width: 52em){
  .mobile{
    display:block!important;
  }
  .none-mobile{
    display:none!important;
  }
  #about-me video{
    top:-450px;
  }
}